import React from 'react';
import {Link} from 'gatsby'
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle, Sandcastle, Clock, ClockRange, ClockStep, ClockViewModel, ImagerySplitDirection, ScreenSpaceEventHandler,ScreenSpaceEventType, Math, Cartesian2, Cartesian3, Transforms, Camera } from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";
import { backgroundColor } from 'styled-system';
import Styles from "./cesium.module.css";

class CesiumContainer extends React.Component {
  divRef = React.createRef();
  viewer = null;
  entity = null;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className={Styles.canvaswrap}>
          <div id="cesiumcontainerdiv">
            <div ref={this.divRef} style={{height:this.props.height}}>
              <div id="slider" className={Styles.slider}></div>
            </div>
          </div>
        </div>

        <div style={{display:"flex",justifyContent: "normal", width:"100%"}}>
          {/* <div className={Styles.buttonyear1}>
            1985
          </div> */}
          {/* <div className={Styles.buttonyear2}>
            2015
          </div> */}
          {/* <div className={Styles.buttonyear3}>
            OFF
          </div> */}
        </div>

        <div style={{display:"flex",justifyContent: "space-between"}}>
          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="originview" value="Home"/>
          </div>
          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="timeset" value="2015"/>
          </div>
          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="timeset2" value="1985"/>
          </div>
          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="timeset3" value="OFF"/>
          </div>
          <div className={Styles.buttonshita}>
            <div className={Styles.buttononoff} id="rotate"></div>
          </div>

          {/* <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="rotate" value="Rotate"/>
          </div> */}
          <div className={Styles.buttonshita}>
            <Link
              className={Styles.buttononoff}
              to={this.props.full_url}
              // to={"/"+this.props.no+"/kokudof"}
              kml_kml={this.props.kml_kml}
              kml_kmz={this.props.kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={this.props.no}
            >
            {this.props.full_name}
            </Link>
          </div>
          <div className={Styles.buttonshita}>
            <input type="button" id="googlemapopen"  className={Styles.buttononoff} value="Map" />
          </div>
          <div className={Styles.buttonshita}>
            <a className={Styles.buttononoff} href={this.props.kml_kmz} download>Earth</a>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {

    // var clock = new Clock({
    //     startTime : JulianDate.fromIso8601('1985-08-30T11:56:04+08'),
    //     stopTime : JulianDate.fromIso8601('2035-08-30T11:56:04+08'),
    //     currentTime : JulianDate.fromIso8601('2017-12-30T11:56:04+08'),
    //     multiplier : 1,
    //     shouldAnimate : true, 
    //     clockRange : ClockRange.LOOP_STOP,
    //     clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    // });

    // var extent = Rectangle.fromDegrees(122, 20, 153, 45);
    // Camera.DEFAULT_VIEW_RECTANGLE = extent;
    // Camera.DEFAULT_VIEW_FACTOR = 0;


/// ビューア起動 ///////
const viewer = new Viewer(this.divRef.current, {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  baseLayerPicker: false,
  timeline : true,
  animation : false,
  fullscreenButton: false,
  homeButton: false,
  vrButton: false,
  geocoder:false,
  sceneModePicker:false,
  navigationHelpButton:false,
  infoBox : false,
  selectionIndicator : false,
  shadows : false,
  // clockViewModel: new ClockViewModel(clock)
});
/////// ビューア起動 ///

/// セシウムのロゴを消す ///////
    viewer._cesiumWidget._creditContainer.style.display = "none";
/////// セシウムのロゴを消す ///

/// スライダー //////////
var layers = viewer.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap.splitDirection = ImagerySplitDirection.LEFT;
var slider = document.getElementById("slider");
viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;
var handler = new ScreenSpaceEventHandler(slider);
var moveActive = false;
function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;
var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 100.0 * splitPosition + "%";
viewer.scene.imagerySplitPosition = splitPosition;
}
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);
handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
////////// スライダー ///

/// GoogleMapを開く //////////
var win;
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = viewer.camera.positionCartographic;
  console.log(cartographic);
          var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
          var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
          var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
          win = window.open(url ,"gmap" ,'width=800,height=600');
}, false);
////////// GoogleMapを開く ///

/// timelineをtrueにしたまま画面から消す //////////
    viewer.timeline.container.style.display = 'none';
    viewer.timeline.resize();
////////// timelineをtrueにしたまま画面から消す ///

/// 地形へのライティングを使う //////////
// viewer.scene.globe.enableLighting = true;
////////// 地形へのライティングを使う ///


/// ONOFF用のタイムライン延長KML準備 ///////
var kmlaaa = '<?xml version="1.0" encoding="UTF-8" ?><kml xmlns="http://earth.google.com/kml/2.0"><Placemark><TimeSpan><begin>'+'1985'+'-10-01'+'</begin><end>'+'2035'+'-10-01'+'</end></TimeSpan><styleUrl>#PolyCol3</styleUrl><Polygon><altitudeMode>relativeToGround</altitudeMode><outerBoundaryIs><LinearRing><coordinates></coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></kml>';
var urlaaa = URL.createObjectURL(new Blob([kmlaaa]));
/////// ONOFF用のタイムライン延長KML準備 ///

/// KMLデータ準備 ///////
    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };
    var dataSource1 = new KmlDataSource.load(this.props.kml_kml,options);
/////// KMLデータ準備 ///

/// KMLデータ読み込み ///////
viewer.dataSources.add(dataSource1).show = true;
viewer.zoomTo(dataSource1
  // , new HeadingPitchRange(0, -1, 2000000)
  );
viewer.dataSources.add(KmlDataSource.load(urlaaa,options));
/////// KMLデータ読み込み ///



/// 回転ボタン //////////
document.getElementById('rotate').innerHTML =
'<div className={Styles.buttononoff} type="button" id="rotate" value="Pin">Pin</div>';
var buttona = document.getElementById('rotate');
buttona.addEventListener('click', function() {
    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue == "Pin") {
        document.getElementById("rotate").value="Stop"
        viewer.clock.onTick.addEventListener(function(clock) {
          viewer.scene.camera.rotateRight(-0.005);
        });
    } else {
          document.getElementById("rotate").value="Pin"
          viewer.zoomTo(dataSource1).then(
              function(){
              var cartographic = viewer.camera.positionCartographic;
              console.log(cartographic);
              var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
              console.log(cartographic.latitude);
              //latitude > +0.003
              var transform = Transforms.eastNorthUpToFixedFrame(center);
              viewer.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
              viewer.clock.onTick.addEventListener(function(clock) {
                viewer.scene.camera.rotateRight(0.005);
              });
              }
          )
          ;
    }
}, false);
////////// 回転ボタン ///

/// KMLのON/OFFボタン //////////
    // document.getElementById('toolbar2').innerHTML =
    // '<div className={Styles.buttononoff}><input type="button" id="onoff" value="Off"/></div>';

    // var buttona = document.getElementById('onoff');
    // buttona.addEventListener('click', function() {
    //     let currentvalue = document.getElementById('onoff').value;
    //     // buttona state changed.
    //     if (currentvalue == "On") {
    //         // Show if not shown.
    //         document.getElementById("onoff").value="Off"
    //         if (!viewer.dataSources.contains(dataSource1)) {
    //             viewer.dataSources.add(dataSource1).show = true;
    //             // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    //         }
    //     } else {
    //         // Hide if currently shown.
    //         // if (viewer.dataSources.contains(dataSource1)) {
    //           document.getElementById("onoff").value="On"
    //           viewer.dataSources.removeAll();
    //           // viewer.dataSources.remove(dataSource1);
    //         // }
    //     }
    // }, false);
////////// KMLのON/OFFボタン ///

/// 視点を戻すボタン（PINも解除） //////////
    var buttona = document.getElementById('originview');
    buttona.addEventListener('click', function() {
                viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    }, false);
////////// 視点を戻すボタン（PINも解除） ///

/// 時間セットボタン //////////
    var buttontimeset = document.getElementById('timeset');
    buttontimeset.addEventListener('click', function() {
      viewer.clock.currentTime =  JulianDate.fromIso8601('2017-08-30T11:56:04+08');
      viewer.clock.multiplier = 1;
      viewer.clock.shouldAnimate = true; //if it was paused.
    }, false);
////////// 時間セットボタン ///

/// 時間セットボタン2 //////////
    var buttontimeset = document.getElementById('timeset2');
    buttontimeset.addEventListener('click', function() {
      viewer.clock.currentTime =  JulianDate.fromIso8601('1995-08-30T11:56:04+08');
      viewer.clock.multiplier = 1;
      viewer.clock.shouldAnimate = true //if it was paused.
    }, false);
////////// 時間セットボタン2 ///

/// 時間セットボタン3 //////////
    var buttontimeset = document.getElementById('timeset3');
    buttontimeset.addEventListener('click', function() {
      viewer.clock.currentTime =  JulianDate.fromIso8601('2025-08-30T11:56:04+08');
      viewer.clock.multiplier = 1;
      viewer.clock.shouldAnimate = true //if it was paused.
    }, false);
////////// 時間セットボタン3 ///

/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

  }
 



  
//   componentDidUpdate() {

// /// KMLデータ準備 ///////
//     var dataSource1 = new KmlDataSource.load(this.props.kml_kml,{
//       camera: this.viewer.scene.camera,
//       canvas: this.viewer.scene.canvas,
//     });
// /////// KMLデータ準備 ///

// /// KMLデータ読み込み ///////
// this.viewer.dataSources.add(dataSource1).show = true;
// this.viewer.zoomTo(dataSource1
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
// /////// KMLデータ読み込み ///

//   }





  // componentWillUnmount() {
  //   this.viewer.destroy();
  // }


}

export default CesiumContainer

